import { TermosStyle } from './styles/Termos.style';
import CabecalhoInfo from '../componentes/cabecalhoInfo';
import CabecalhoMenu from '../componentes/cabecalhoMenu';
import TituloPagina from '../componentes/tituloPagina';
import Rodape from '../componentes/rodape';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines, faHeartPulse } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import IrAoTopoWidget from '../componentes/irAoTopoWidget';
import SeoHelmet from '../componentes/seoHelmet';

const Lwm = () => {
    return (
        <>
            <SeoHelmet 
                conector=' - '
                subtitulo='Dados Empresariais'
                linkcanonical='lwm'
            />
            <CabecalhoInfo />
            <CabecalhoMenu />
            <TituloPagina titulo='ANTIGO PROVAS DE TI' 
                          h1ClassName='center'
                          hiddenClassName='hidden'
                          link='Antigo Provas De TI'
                          info=''
                          getStyle={() => {}}
            />

            <TermosStyle>
                <h2><FontAwesomeIcon icon={faHeartPulse} /> OUT/2009 - DEZ/2018 <FontAwesomeIcon icon={faHeart} /></h2>
                <span><FontAwesomeIcon icon={faFileLines} color='#27921A' fontSize='24px' /></span>

                <div className='termo'>
                    <h3>Como acesso o antigo site?</h3>
                    <div className='color-rule'></div>

                    <p>
                        Ele não existe mais desde <b>31/DEZ/2018</b>. As vendas de materiais no antigo formato (para <b>DOWNLOAD</b>) foram interrompidas e uma nova plataforma substituiu a anterior. Já estamos no <b>SÉTIMO ANO</b> com um novo formato de vendas, uma nova base de dados, nova participação societária, nova Razão Social e, até mesmo, novo CNPJ.
                    </p>
                </div>
                <div className='rule'></div>

                <div className='termo'>
                    <h3>E os materiais da plataforma antiga?</h3>
                    <div className='color-rule'></div>

                    <p>
                        Eram <b>BAIXADOS</b> para usar... E havia um <b>PRAZO</b> para fazê-lo. Não eram assistidos na plataforma. O procedimento na plataforma antiga era adquirir e <b>BAIXAR PARA USAR</b>. A maioria destes materiais <b>NÃO EXISTEM MAIS</b> na plataforma, pois já foram <b>SUBSTITUÍDOS</b>. Todos os pedidos daquela época estão <b>fora de qualquer prazo razoável há anos</b>. Verifique a data de sua aquisição nos e-mails da transação ou em seu gateway de pagamento (PayPal ou PagSeguro).
                    </p>
                </div>
                <div className='rule'></div>

                <div className='termo'>
                    <h3>Havia mesmo um limite de tempo?</h3>
                    <div className='color-rule'></div>

                    <p>
                        Sim, <b>SEMPRE HOUVE</b> um limite... Esta informação sempre esteve em praticamente todas as páginas do site. Estava nos <b>Termos e Condições</b>, estava no <b>FAQ</b>, estava nos <b>e-mails</b> enviados durante a transação, estava na descrição de cada um dos produtos etc. Na verdade, praticamente <b>TODA A COMUNIDADE</b> sabia disso, uma vez que o download era o nosso maior diferencial na época.
                    </p>

                    <p>
                        Mas, para ajudar a lembrar, seguem armazenamentos públicos das nossas páginas no <b>Web.Archive.Org</b> ao longo dos anos.
                    </p>

                    <p>
                        Dos <b>Termos e Condições</b> em: <a href='https://web.archive.org/web/20140625045720/http://www.provasdeti.com.br/institucional-termos-e-condicoes/'>2014</a>, <a href='https://web.archive.org/web/20151011140735/http://www.provasdeti.com.br/institucional-termos-e-condicoes/'>2015</a>, <a href='https://web.archive.org/web/20160903002941/http://www.provasdeti.com.br/institucional-termos-e-condicoes/'>2016</a>, <a href='https://web.archive.org/web/20171125101159/http://www.provasdeti.com.br/institucional-termos-e-condicoes/'>2017</a>, <a href='https://web.archive.org/web/20180726234052/http://www.provasdeti.com.br/institucional-termos-e-condicoes/'>2018</a> etc.
                        <br></br>
                        Do <b>FAQ</b> em: <a href='https://web.archive.org/web/20140625050640/http://www.provasdeti.com.br/faq/'>2014</a>, <a href='https://web.archive.org/web/20150502202421/http://www.provasdeti.com.br/faq/'>2015</a>, <a href='https://web.archive.org/web/20161013213604/http://www.provasdeti.com.br/faq/'>2016</a>, <a href='https://web.archive.org/web/20170925222436/http://www.provasdeti.com.br/faq/'>2017</a>, <a href='https://web.archive.org/web/20180812231210/http://www.provasdeti.com.br/faq/'>2018</a> etc.
                    </p>
                </div>
                <div className='rule'></div>

                <div className='termo'>
                    <h3>E quem perdeu o HD ou não baixou o material?</h3>
                    <div className='color-rule'></div>

                    <p>
                        Armazenar conteúdo de terceiros na nuvem tem custo. Permitir o <b>download</b> tinha como base o fato de que, uma vez baixado, seria <b>responsabilidade do aluno</b> cuidar do material. Caso fosse fornecido um serviço de armazenamento perpétuo em nuvem, haveria uma <b>COBRANÇA MENSAL</b> adequada para prestar tal serviço. Não haveria, pelo contrário, um prazo para download <b>definido e amplamente divulgado</b> justamente <b>por não querermos fornecer serviço de armazenamento perpétuo</b> e querermos poder retirar materiais da plataforma quando fosse necessário (atualização, troca de professor etc.).
                    </p>
                </div>
                <div className='rule'></div>

                <div className='termo'>
                    <h3>E o conteúdo não existe mais?</h3>
                    <div className='color-rule'></div>

                    <p>
                        Por razões óbvias, sempre reservamos o direito de retirar materiais do ar (<b>custos de armazenamento, saída de professor, alterações de conteúdo, fechamento da empresa</b>, enfim...). E, justamente por isso, <b>sempre</b> houve um PRAZO PARA DONWLOAD após a compra e suporte para auxiliar a quem tivesse problemas. Muitos materiais daquela época nem existem mais, pois foram retirados ou substituídos.
                    </p>
                </div>
                <div className='rule'></div>

                <div className='termo'>
                    <h3>Como verifico minhas compras e datas?</h3>
                    <div className='color-rule'></div>

                    <p>
                        Esta questão de data <b>nem mesmo é relevante</b>, uma vez que o formato atual já possui alguns anos. Portanto, a aquisição antiga certamente está <b>anos fora de qualquer prazo razoável</b> além daquilo que já era previsto. De qualquer modo, o aluno tem esta informação nos e-mails transacionais enviados na época e, principalmente, em sua própria conta no gateway de pagamento (PAYPAL, PAGSEGURO etc.).
                    </p>
                </div>
                <div className='rule'></div>

                <div className='termo'>
                    <h3>E a antiga LWM?</h3>
                    <div className='color-rule'></div>

                    <p>
                        A empresa não existe mais há anos. "LWM Tecnologia, Serviços e Comércio Ltda" foi a Razão Social que vigorou entre 2009 e 2018. Tal Razão Social, assim como a sociedade, foi desfeita e interrompida em 2018. Seu CNPJ foi baixado para que uma nova plataforma pudesse nascer do zero em uma nova empresa, com novos sócios e um novo formato.
                    </p>

                    <p>
                        Apenas a marca <b>Provas de TI</b> permaneceu por razões históricas.
                    </p>
                </div>
                <div className='rule'></div>

            </TermosStyle>

            <Rodape />

            <IrAoTopoWidget />
        </>
    )
}

export default Lwm;